<template>
	<div class="certificateWrap">
		<el-row :gutter="20">
			<el-col
				:span="8"
				v-for="(item, index) in certificateList"
				:key="index"
			>
				<div class="bottomItem" @click="jumpInfo(item)">
					<img :src="item.img" alt="" />
					<div class="itemInfo">
						<p class="ellis1">{{ item.title }}</p>
						<p>
							<span v-if="item.status == 1" class="colorBlue"
								>兑换审核中</span
							>
							<span v-if="item.status == 2">已兑换</span>
						</p>
					</div>
				</div>
			</el-col>
		</el-row>
		<p class="moreBtn" @click="getMore">
			<i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			page: 1,
			btnMessage: '加载更多',
			certificateList: [],
		};
	},
	mounted() {
		this.certificateList = [
			{
				id: 1,
				img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
				title: '21考研考前英语阅读写作21考研考前英语阅读写作',
				status: 1,
			},
			{
				id: 1,
				img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
				title: '21考研考前英语阅读写作',
				status: 1,
			},
			{
				id: 1,
				img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
				title: '21考研考前英语阅读写作',
				status: 2,
			},
			{
				id: 1,
				img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
				title: '21考研考前英语阅读写作',
				status: 2,
			},
			{
				id: 1,
				img: 'https://z3.ax1x.com/2021/11/11/IwNP6x.png',
				title: '21考研考前英语阅读写作',
				status: 2,
			},
		];
	},
	methods: {
		getExchangeList() {},
		getMore() {
			if (this.btnMessage == '加载更多') {
				this.page++;
				this.getExchangeList();
			}
		},
		jumpInfo(item) {
			this.$router.push({
				path: '/userCertificateInfo',
				meta: {
					name: '我的证书',
				},
				query: {
					id: item.id,
				},
			});
		},
	},
};
</script>

<style scoped lang="scss">
.certificateWrap {
	background: #fff;
	padding: 25px 20px 30px;
	box-sizing: border-box;
	.bottomItem {
		height: 216px;
		background: #fff;
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.06);
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		cursor: pointer;
		border-radius: 6px;
		img {
			width: 100%;
			height: 140px;
			border-radius: 6px 6px 0 0;
		}
		.itemInfo {
			height: 76px;
			padding: 13px 20px;
			box-sizing: border-box;
			font-size: 16px;
			border-radius: 0 0 6px 6px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.ellis1 {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			span {
				font-size: 14px;
				color: #999;
			}
			.colorBlue {
				color: var(--default-color);
			}
		}
	}
	.moreBtn {
		margin: 0 auto;
		margin-top: 10px;
		width: 100px;
		height: 30px;
		font-size: 14px;
		border-radius: 18px;
		border: 1px solid #e1e1e1;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		color: #666;
		&:hover {
			background: var(--change-color);
			border: 1px solid var(--change-color);
			color: #fff;
		}
		i {
			font-size: 14px;
		}
	}
}
</style>
